export const downloadSections = [
  {
    icon: 'server',
    sectionTitle: 'Server',
    title: 'FileCloud Server',
    id: 'server',
    description:
      'Download and install the FileCloud Server for your operating system.',
    info: '',
    documentationUrl:
      'https://www.getfilecloud.com/supportdocs/display/cloud/Installing+FileCloud+Server',
    downloadOptions: [
      {
        title: 'Server',
        id: 'server',
        links: [
          {
            id: 'CEREBRO_WINDOWS_SERVER',
            icon: 'windows',
            title: 'Windows 64 bit',
            url: '#',
          },
          {
            id: 'CEREBRO_LINUXDEB_SERVER',
            icon: 'linux',
            title: 'Linux',
            description: 'X86-64 for Ubuntu (Deb Package)',
            url: '#',
          },
          /*  {
            id: 'CEREBRO_LINUXRPM_SERVER',
            icon: 'linux',
            title: 'Linux',
            description: 'X86-64 for Redhat/Fedora/CentOS (RPM Package)',
            url: '#',
          }, */
          {
            id: 'CEREBRO_OVF_SERVER',
            icon: 'linux',
            title: 'Linux',
            description: 'Virtual Machine (VMWare, VirtualBox, etc)',
            url: '#',
          },
          {
            id: 'CEREBRO_AWS_SERVER',
            icon: 'server',
            iconFamily: 'fad',
            title: 'AWS',
            url: '#',
          },
          {
            id: 'CEREBRO_AZURE_SERVER',
            icon: 'windows',
            title: 'Azure',
            url: '#',
          },
          {
            id: 'CEREBRO_DOCKER_SERVER',
            icon: 'server',
            iconFamily: 'fad',
            title: 'Docker',
            url: '#',
          },
        ],
      },
    ],
  },
  {
    icon: 'shield-alt',
    sectionTitle: 'Desktop Apps',
    id: 'desktop',
    title: 'FileCloud Desktop',
    description: 'All-in-one client for online & offline file sync and desktop access.',
    info: '',
    documentationUrl:
        'https://www.filecloud.com/supportdocs/fcdoc/latest/server/filecloud-client-applications-and-add-ins/filecloud-desktop-for-macos',
    downloadOptions: [
      {
        title: 'Desktop',
        links: [
          {
            id: 'CEREBRO_MAC_DESKTOP',
            icon: 'apple',
            title: 'MacOS',
            url: '#',
          },
          {
            id: 'CEREBRO_WINDOWS_DESKTOP',
            icon: 'windows',
            title: 'Windows',
            url: '#',
          },
        ],
      },
    ],
  },
  {
    icon: 'sync',
    sectionTitle: 'Desktop Apps',
    id: 'sync',
    title: 'FileCloud Sync',
    description: 'Sync Files from your Desktop Computer to the Cloud',
    info: '',
    documentationUrl:
      'https://www.getfilecloud.com/supportdocs/display/cloud/FileCloud+Sync',
    downloadOptions: [
      {
        title: 'Sync 2',
        links: [
          {
            id: 'CEREBRO_WINDOWS_SYNC2CLIENT',
            icon: 'windows',
            title: 'Windows',
            url: '#',
          },
          {
            id: 'CEREBRO_MAC_SYNC2CLIENT',
            icon: 'apple',
            title: 'MacOS',
            url: '#',
          },
          {
            id: 'CEREBRO_LINUX_SYNC2CLIENT',
            icon: 'linux',
            title: 'Linux',
            description: 'X86-64',
            url: '#',
          },
        ],
      },
      // {
      //   title: 'Sync classic',
      //   links: [
      //     {
      //       id: 'CEREBRO_WINDOWS_SYNCCLIENT',
      //       icon: 'windows',
      //       title: 'Windows',
      //       url: '#',
      //     },
      //     {
      //       id: 'CEREBRO_MAC_SYNCCLIENT',
      //       icon: 'apple',
      //       title: 'MacOS',
      //       description: 'X86-64 for 10.8+',
      //       url: '#',
      //     },
      //     {
      //       id: 'CEREBRO_LINUX_SYNCCLIENT',
      //       icon: 'linux',
      //       title: 'Linux',
      //       description: 'X86-64',
      //       url: '#',
      //     },
      //   ],
      // },
    ],
  },
  {
    icon: 'hdd',
    sectionTitle: 'Desktop Apps',
    id: 'drive',
    title: 'FileCloud Drive',
    description: 'Mount your Cloud as Drive Letter on Windows and Mac OSX*',
    info: 'Drive is only available on macs with Intel CPUs at this time',
    documentationUrl:
      'https://www.getfilecloud.com/supportdocs/display/cloud/FileCloud+Drive',
    downloadOptions: [
      {
        title: 'Drive classic',
        links: [
          {
            id: 'CEREBRO_WINDOWS_DRIVE_CLIENT',
            icon: 'windows',
            title: 'Windows',
            url: '#',
          },
          /*
          {
            icon: 'linux',
            title: 'Linux',
            description: 'X86-64',
            url: '#',
          },
          */
          {
            id: 'CEREBRO_MAC_DRIVE_CLIENT',
            icon: 'apple',
            title: 'MacOS',
            url: '#',
          },
        ],
      },
    ],
  },
  {
    icon: 'file-search',
    sectionTitle: 'Desktop Apps',
    title: 'FileCloud Secure Viewer',
    id: 'secure',
    description:
      'Secure document viewer that protects important information from intellectual property theft, data leak and piracy',
    info: '',
    documentationUrl:
      'https://www.filecloud.com/supportdocs/display/cloud/DRM+for+exporting+secure+documents',
    downloadOptions: [
      {
        title: 'FileCloud secure',
        links: [
          {
            id: 'CEREBRO_WINDOWS_SECUREVIEWER',
            icon: 'windows',
            title: 'Windows',
            url: '#',
          },
          {
            id: 'CEREBRO_MAC_SECUREVIEWER',
            icon: 'apple',
            title: 'MacOS',
            url: '#',
          },
        ],
      },
    ],
  },
  {
    icon: 'envelope-open-text',
    sectionTitle: 'Desktop Apps',
    title: 'Microsoft Outlook Add-In',
    id: 'outlook',
    description:
      'FileCloud Outlook add-in brings the powerful features of filesharing to the Windows desktop and Outlook',
    info: '',
    documentationUrl:
      'https://www.filecloud.com/supportdocs/display/cloud/FileCloud+Add-In+for+Microsoft+Outlook',
    downloadOptions: [
      {
        title: 'Outlook Add-In',
        links: [
          {
            id: 'CEREBRO_OUTLOOK_PLUGIN',
            icon: 'windows',
            title: 'Windows',
            url: '#',
          },
        ],
      },
    ],
  },

  {
    icon: 'mobile-alt',
    sectionTitle: 'Mobile Apps',
    title: 'FileCloud Mobile',
    id: 'mobile',
    description: 'Install mobile apps to access your cloud from anywhere',
    info: '',
    documentationUrl:
      'https://www.getfilecloud.com/supportdocs/display/cloud/FileCloud+Mobile+Access',
    downloadOptions: [
      {
        title: 'Mobile apps',
        links: [
          {
            id: 'CEREBRO_ANDROID_CLIENT',
            icon: 'android',
            title: 'Android',
            url: '#',
          },
          {
            id: 'CEREBRO_IOS_CLIENT',
            icon: 'apple',
            title: 'iOS',
            url: '#',
          },
        ],
      },
    ],
  },
];

export const otherDownloads = [
  {
    id: 'CEREBRO_SERVER_SYNC',
    label: 'Server Sync for Windows',
    description:
      'Synchronize files and permissions from local Windows file servers to the cloud',
    url: '#',
  },
  {
    id: 'CEREBRO_NTFS_HELPER',
    label: 'Windows Helper',
    description:
      'Handle NTFS Permission checks for Network Folders configured with NTFS permissions',
    url: '#',
  },
  /*  {
    id: 'CEREBRO_OUTLOOK_PLUGIN',
    label: 'Microsoft Outlook Add-In for Windows',
    url: '#',
  }, */
  /*   {
    id: 'CEREBRO_OFFICE_PLUGIN',
    label: 'Microsoft Office Add-In for Windows',
    url: '#',
  }, */
  {
    id: 'CEREBRO_AUTOMATION_APP',
    label: 'Commandline Automation App for Windows',
    description:
      'Enable command line execution of some basic FileCloud actions',
    url: '#',
  },
];
